import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';
import { store } from 'react-notifications-component';
import { Button, Modal, Input, Dimmer, Loader} from 'semantic-ui-react';
import '../scss/Rules.scss';
import ContentTopline from '../components/ContentTopline';
import cloverLogo from '../Assets/clover_new.png';
import shopifyLogo from '../Assets/shopify_new.png';
import dualRing from '../Assets/DualRing.gif';
import alertIcon from '../Assets/error.png'
import { gsap } from 'gsap';
import axios from 'axios';
import { navigate } from '@reach/router';
import Spinner from '../components/Spinner/Spinner';

const Switch = ({ switchValue, handleSwitchClick }) => {
  return (
    <>
      <div className="rules_button_group_container">
        <Button.Group>
          <Button
            color={switchValue === 1 ? 'blue' : 'grey'}
            onClick={() => handleSwitchClick(1)}
          >
            C → S
          </Button>

          <Button
            color={switchValue === 0 ? 'blue' : 'grey'}
            onClick={() => handleSwitchClick(0)}
          >
            None
          </Button>

          <Button
            color={switchValue === 2 ? 'blue' : 'grey'}
            onClick={() => handleSwitchClick(2)}
          >
            C ← S
          </Button>
        </Button.Group>
      </div>
    </>
  );
};

// <Rules path='/rules' omniMid={mid} token={authToken} />

const networkErrorHandler = (error) => {
  if (error.response) {
    // console.log('error.response.data', error.response.data);
    navigate('/error', {
      state: {
        message:
          'The request was made and the server responded with a status code that falls out of the range of 2xx',
        data: error.response.data,
      },
    });

    // console.log(error.response.headers);
  } else if (error.request) {
    navigate('/error', {
      state: {
        message: 'The request was made but no response was received',
        url: error.config.url,
        method: error.config.method,
      },
    });
  } else {
    // Something happened in setting up the request that triggered an Error
    //console.log('Error', error.message);

    navigate('/error', {
      state: {
        message: error.message,
      },
    });
  }
  // console.log(error.config);
};

const Rules = ({ mid, token, userData }) => {
  const [switchValue, setSwitch] = useState(undefined);
  const [confirmMessage, setConfirmMessage] = useState(false)
  const [confirmInput, setConfirmInput] = useState('')
  const [tempValue, setTempValue] = useState(0)
  const [isSync, setIsSync] = useState(false)

  const handleCancelClick = () => {
    setConfirmMessage(false)
    setTempValue(0)
    setConfirmInput('')
  }

  const handleChangeInput = (e) => {
    setConfirmInput(e.target.value)
  }

  const handleSubmitForm = () => {
    if (confirmInput.toLowerCase() === 'confirm') {
      const changeFetchSyncStatus = async () => {
        let formData = new FormData();

        const newValue = tempValue === 1 ? 'Clover' : tempValue === 0 ? 'None' : 'Shopify';
        formData.append('source', newValue);

        const res = await axios
          .post(
            `https://omnigateway.net/api/${mid}/settings/changeSource`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
                // Authorization: `Bearer D8VZ9OTKSSXX`,
              },
            }
          )
          .then((res) => {
            // console.log(res.data);
            localStorage.setItem('SyncSource', newValue);
            setSwitch(+tempValue);
            setIsSync(true)
            axios.post(`https://omnigateway.net/api/${mid}/sync`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
              .then((res) => {
                setIsSync(false)
              })
              .catch((error) => {
                setIsSync(false)
                networkErrorHandler(error)
              });
          })
          .catch(networkErrorHandler);
      };

      changeFetchSyncStatus();
      setConfirmMessage(false)
      setConfirmInput('')
    }
  }

  const handleSwitchClick = (value) => {
    // if (value === 0) {
    if (value === 1 || value === 2) {
      console.log(userData.clMid);
      console.log(userData.shMid);
      console.log(!userData.clMid || userData.shMid === 0) ;
      if (!userData.clMid || userData.shMid === 0) {
        store.addNotification({
          message: "Please add your Clover and Shopify account",
          type: "warning",
          container: "bottom-right",
          dismiss: {
            duration: 5000,
          }
        });
      } else {
        setConfirmMessage(true)
        setTempValue(value)
      }
    } else {
      setConfirmMessage(true)
      setTempValue(value)
    }
    // } else {
    //   const changeFetchSyncStatus = async () => {
    //     let newValue = ''
    //     let formData = new FormData();
    //     switch (value) {
    //       case 1:
    //         newValue = "Clover"
    //         break;
    //       case 2:
    //         newValue = "Shopify"
    //         break;
    //       case 0:
    //         newValue = "None"
    //         break;
    //       default:
    //         break;
    //     }
    //     formData.append('source', newValue);

    //     const res = await axios
    //       .post(
    //         `https://omnigateway.net/api/${mid}/settings/changeSource`,
    //         formData,
    //         {
    //           headers: {
    //             'Content-Type': 'multipart/form-data',
    //             Authorization: `Bearer ${token}`,
    //             // Authorization: `Bearer D8VZ9OTKSSXX`,
    //           },
    //         }
    //       )
    //       .then((res) => {
    //         // console.log(res.data);
    //         localStorage.setItem('SyncSource', newValue);
    //         setSwitch(value);
    //         axios.post(`https://omnigateway.net/api/${mid}/sync`,
    //           {},
    //           {
    //             headers: {
    //               Authorization: `Bearer ${token}`,
    //             },
    //           }
    //         )
    //           .catch(networkErrorHandler);
    //       })
    //       .catch(networkErrorHandler);
    //   };

    //   changeFetchSyncStatus();
    // }
  };

  useEffect(() => {

    const currentSource = localStorage.getItem('SyncSource');
    switch (currentSource) {
      case 'None':
        setSwitch(0)
        // document.getElementById('on').checked = false;
        // document.getElementById('off').checked = false;
        // document.getElementById('none').checked = true;
        break;
      case 'Clover':
        setSwitch(1);
        // document.getElementById('on').checked = true;
        // document.getElementById('off').checked = false;
        // document.getElementById('none').checked = false;
        break;
      case 'Shopify':
        setSwitch(2);
        // document.getElementById('off').checked = true;
        // document.getElementById('on').checked = false;
        // document.getElementById('none').checked = false;
        break;
      default:
        break;
    }

    const fetchSyncStatus = async () => {
      const res = await axios
        .get(`https://omnigateway.net/api/${mid}/info`, {
          // .get(`https://omnigateway.net/api/691/info`, {
          headers: {
            Authorization: `Bearer ${token}`,
            // Authorization: `Bearer D8VZ9OTKSSXX`,
          },
        })
        .then((result) => {
          const syncStatus = result?.data?.source;


          switch (syncStatus) {
            case 'None':
              setSwitch(1);
              localStorage.setItem('SyncSource', 'None');
              // document.getElementById('on').checked = false;
              // document.getElementById('off').checked = false;
              // document.getElementById('none').checked = true;
              break;
            case 'Clover':
              setSwitch(1);
              localStorage.setItem('SyncSource', 'Clover');
              // document.getElementById('on').checked = true;
              // document.getElementById('off').checked = false;
              // document.getElementById('none').checked = false;
              break;
            case 'Shopify':
              setSwitch(2);
              localStorage.setItem('SyncSource', 'Shopify');
              // document.getElementById('off').checked = true;
              // document.getElementById('on').checked = false;
              // document.getElementById('none').checked = false;
              break;
            default:
              break;
          }
        })
        .catch(networkErrorHandler);
    };

    // if (switchValue === undefined) {fetchSyncStatus()
    // }


    if (!!mid && !!token && (switchValue === undefined)) {
      fetchSyncStatus();
    }
  }, [mid, token, switchValue]);

  useEffect(() => {
    const getRelativePos = (obj) => {
      const pos = { offsetLeft: 0, offsetTop: 0 };
      while (obj != null) {
        pos.offsetLeft += obj.offsetLeft;
        pos.offsetTop += obj.offsetTop;
        obj = obj.offsetParent;
      }
      return pos;
    };

    const animation = new gsap.timeline({ repeat: -1, repeatDelay: 0 });
    const animation2 = new gsap.timeline({ repeat: -1, repeatDelay: 0 });
    const animation3 = new gsap.timeline({ repeat: -1, repeatDelay: 0 });
    const animation4 = new gsap.timeline({ repeat: -1, repeatDelay: 0 });

    let el1 = document.querySelector('.first-right-arrow');
    let el2 = document.querySelector('.first-left-arrow');

    const pos1 = getRelativePos(el1);
    const pos2 = getRelativePos(el2);
    const dx = pos2.offsetLeft - pos1.offsetLeft;

    let el3 = document.querySelector('.third-right-arrow');
    let el4 = document.querySelector('.third-left-arrow');

    const pos3 = getRelativePos(el3);
    const pos4 = getRelativePos(el4);
    const dx2 = pos4.offsetLeft - pos3.offsetLeft;

    switch (switchValue) {
      case 1:
        gsap.to('.first-right-arrow', { delay: 0, duration: 0, opacity: 1 });
        gsap.to('.second-right-arrow', { delay: 0, duration: 0, opacity: 1 });
        gsap.to('.third-right-arrow', { delay: 0, duration: 0, opacity: 1 });
        gsap.to('.fourth-right-arrow', { delay: 0, duration: 0, opacity: 1 });

        animation.fromTo(
          '.first-right-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: dx }
        );
        animation2.fromTo(
          '.second-right-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: dx }
        );
        animation3.fromTo(
          '.third-right-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: dx2 }
        );
        animation4.fromTo(
          '.fourth-right-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: dx2 }
        );

        gsap.to('.first-left-arrow', { delay: 0, duration: 0, opacity: 0 });
        gsap.to('.second-left-arrow', { delay: 0, duration: 0, opacity: 0 });
        gsap.to('.third-left-arrow', { delay: 0, duration: 0, opacity: 0 });
        gsap.to('.fourth-left-arrow', { delay: 0, duration: 0, opacity: 0 });

        break;
      case 0:
        gsap.to('.first-right-arrow', { delay: 0, duration: 0, opacity: 1 });
        gsap.to('.third-right-arrow', { delay: 0, duration: 0, opacity: 1 });
        gsap.to('.second-left-arrow', { delay: 0, duration: 0, opacity: 1 });
        gsap.to('.fourth-left-arrow', { delay: 0, duration: 0, opacity: 1 });

        animation.fromTo(
          '.first-right-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: dx }
        );
        animation2.fromTo(
          '.third-right-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: dx2 }
        );
        animation3.fromTo(
          '.second-left-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: -dx }
        );
        animation4.fromTo(
          '.fourth-left-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: -dx2 }
        );

        gsap.to('.second-right-arrow', { delay: 0, duration: 0, opacity: 0 });
        gsap.to('.first-left-arrow', { delay: 0, duration: 0, opacity: 0 });
        gsap.to('.third-left-arrow', { delay: 0, duration: 0, opacity: 0 });
        gsap.to('.fourth-right-arrow', { delay: 0, duration: 0, opacity: 0 });
        break;
      case 2:
        gsap.to('.first-left-arrow', { delay: 0, duration: 0, opacity: 1 });
        gsap.to('.second-left-arrow', { delay: 0, duration: 0, opacity: 1 });
        gsap.to('.third-left-arrow', { delay: 0, duration: 0, opacity: 1 });
        gsap.to('.fourth-left-arrow', { delay: 0, duration: 0, opacity: 1 });

        animation.fromTo(
          '.first-left-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: -dx }
        );
        animation2.fromTo(
          '.second-left-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: -dx }
        );
        animation3.fromTo(
          '.third-left-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: -dx2 }
        );
        animation4.fromTo(
          '.fourth-left-arrow',
          { x: 0 },
          { delay: 0, duration: 2, x: -dx2 }
        );

        gsap.to('.first-right-arrow', { delay: 0, duration: 0, opacity: 0 });
        gsap.to('.second-right-arrow', { delay: 0, duration: 0, opacity: 0 });
        gsap.to('.third-right-arrow', { delay: 0, duration: 0, opacity: 0 });
        gsap.to('.fourth-right-arrow', { delay: 0, duration: 0, opacity: 0 });

        break;
      default:
        break;
    }
  }, [switchValue]);

  return (
    <>
      <div>
        {mid ? (
          <div className='rules-column'>
            <ContentTopline title='Clover and Shopify Mapping Rules Scheme' />
            <Switch switchValue={switchValue} handleSwitchClick={handleSwitchClick} />
            <Dimmer active={isSync} inverted>
              <Loader size='huge' inverted>Synchronization is in progres, it may take up to 5 minutes</Loader>
            </Dimmer>
            <div className='rulesContainer'>
              <div className='rulesItemsTitle'>
                <div className='rulesLogo sync-text'>
                  Live Sync{' '}
                  <img
                    style={{ marginLeft: '20px' }}
                    className=''
                    src={dualRing}
                    alt='dualRing'
                    width='55px'
                  />
                </div>
              </div>

              <div className='rulesItemsContainer'>
                <div className='rulesItemsHorizontalContainer'>
                  <div className='rulesLogo'>
                    {' '}
                    <img className='logo--small2' src={cloverLogo} alt='Clover' />
                    Clover Items
                  </div>

                  <div className='rulesItemsVerticalContainer'>
                    <div className='first-right-arrow arrow '>→</div>
                    <div className='second-right-arrow arrow'>→</div>
                  </div>
                </div>

                <div className='rulesItemsHorizontalContainer'>
                  <div className='rulesItemsVerticalContainer'>
                    <div className='first-left-arrow arrow'>←</div>
                    <div className='second-left-arrow arrow'>←</div>
                  </div>

                  <div className='rulesLogo'>
                    {' '}
                    <img className='logo--small2' src={shopifyLogo} alt='Shopify' />
                    Shopify Products
                  </div>
                </div>
              </div>

              <div className='setupStep'>
                {' '}
                View and understand the mapping rules for{' '}
                <Link to='/products' className='step-link'>
                  items
                </Link>{' '}
              </div>
            </div>

            <div className='rulesContainer'>
              <div className='rulesItemsTitle'>
                <div className='rulesLogo sync-text'>Batch Sync (on demand)</div>
              </div>

              <div className='rulesItemsContainer'>
                <div className='rulesItemsHorizontalContainer'>
                  <div className='rulesLogo'>
                    <img className='logo--small2' src={cloverLogo} alt='Clover' />
                    Clover Categories
                  </div>

                  <div className='rulesItemsVerticalContainer'>
                    <div className='third-right-arrow arrow'>→</div>
                    <div className='fourth-right-arrow arrow'>→</div>
                  </div>
                </div>

                <div className='rulesItemsHorizontalContainer'>
                  <div className='rulesItemsVerticalContainer'>
                    <div className='third-left-arrow arrow'>←</div>
                    <div className='fourth-left-arrow arrow'>←</div>
                  </div>

                  <div className='rulesLogo'>
                    <img className='logo--small2' src={shopifyLogo} alt='Shopify' />
                    Shopify Collections
                  </div>
                </div>
              </div>

              <div className='setupStep'>
                {' '}
                View and understand the mapping rules for{' '}
                <Link to='/categories' className='step-link'>
                  categories
                </Link>
              </div>
            </div>
          </div>
        ) : <Spinner />}
      </div>

      <Modal open={confirmMessage} centered size='tiny'>
        <Modal.Header className='confirm_modal_header' >Confirm Direction of Sync</Modal.Header>
        <Modal.Content>
          <div className="confirm confirm_form_container">
            <div className="confirm confirm_form_header">
              <img src={alertIcon} alt="alertIcon" width="55px" />
            </div>
            <div className="confirm confirm_form_content">
              <div>
                Direction of data sync determines what data will be
                used as a system of record to update the other. For
                example if you choose Sync from Clover to Shopify
                then changes made on Clover will be made to Shopify.
                However the changes made to Shopify will not be
                shown on Clover. And Vise Versa is true.
              </div>
              <div>
                This action may cause some data to be permanently
                delete or overwritten. Please ensure you understand
                and wish to proceed.
              </div>
              <div>
                If you are sure you want to select this sync direction,
                type 'confirm' in the box below to enable the confirm
                button, then click confirm to proceed.
              </div>
            </div>
            <div>
              <Input className="confirm confirm_form_input" value={confirmInput} onChange={(e) => handleChangeInput(e)} />
            </div>
            <div className="confirm confirm_form_button_container" onClick={() => handleSubmitForm()}>
              <button
                className="confirm confirm_form_confirm_button"
                disabled={confirmInput.toLowerCase() !== 'confirm'}
                style={{ cursor: confirmInput.toLowerCase() !== 'confirm' ? 'not-allowed' : 'pointer' }}
              >
                Confirm
              </button>
              <button className="confirm confirm_form_cancel_button" onClick={() => handleCancelClick()}>Cancel</button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default Rules;
