import React from "react";

export default function Catch(
  component,
  errorHandler)  {
    function Inner({ error, props }) {
    return <React.Fragment>{component(props, error)}</React.Fragment>
  }

  return class extends React.Component {
      state = {
        error: undefined
    }
    
    static getDerivedStateFromError(error) {
      return { error }
    }
    
    componentDidCatch(error, info) {
      if (errorHandler) {
        errorHandler(error, info)
      }
    }
    
    render() {
      return <Inner error={this.state.error} props={this.props} />
    }
  }
}
