import React from "react";

import "../scss/ContentTopline.scss";

import omniIcon from "../Assets/omnigateway_icon.png";

const ContentTopline = (props) => {
  return (
    <div className="title-topline">
      <img className="logo--omni" src={omniIcon} alt="OMNIGATEWAY.NET" />
      <h4 className="title-text">{props.title}</h4>
    </div>
  );
};

export default ContentTopline;
