import React from "react";
import { Link } from "@reach/router";
import "../scss/Header.scss";

const Header = (props) => {
  const handleLogout = () => {
    localStorage.clear()
    window.location.reload()
  }
  return (
    <div className="header">
      <Link
        className="header-link"
        to={props.authToken ? '/' : '/login'}
      >
        <h1 className="logo--header">OMNIGATEWAY.NET</h1>
      </Link>

      <div className="user-container">
        { props.mid || props.authToken ? 
          <button className="logout-button" onClick={() => handleLogout()}>Logout</button>
      : null }
        </div>
    </div>
  );
};

export default Header;
