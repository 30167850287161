import React from 'react';
import errorImg from '../Assets/error.png';
import { navigate } from '@reach/router';
// import { location } from '@reach/router';
import '../scss/error.scss';

const NetworkError = (props) => {
  //console.log(props.location.state);

  const { message, data, url, method } = props.location.state;

  //   console.log('message', message);
  //   console.log('data', data);
  //   console.log('status: ', data?.status);
  //   console.log('error:', data?.error);
  //   console.log('path:', data?.path);
  //   console.log('url', url);
  //   console.log('method', method);

  return (
    <div className='errorContainer'>
      <img
        style={{ marginTop: '20px', marginBottom: '20px' }}
        className=''
        src={errorImg}
        alt='error'
        width='50px'
      />
      <h2>Network Error Page </h2>
      {message ? <h4 className="errorItem">Message: {message} </h4> : null}
      {data?.status ? <h4 className="errorItem">Status: {data?.status} </h4> : null}
      {data?.error ? <h4 className="errorItem">Error: {data?.error} </h4> : null}
      {data?.path ? <h4 className="errorItem">Path: {data?.path} </h4> : null}
      {url ? <h4 className="errorItem">Url: {url} </h4> : null}
      {method ? <h4 className="errorItem">Method: {method} </h4> : null}

      <div className='errorButton'>
        <button
          onClick={() => {
            navigate('/');
          }}
          className='button'
        >
          Return to Home Page
        </button>
      </div>
    </div>
  );
};

export default NetworkError;
