import React, { useState } from "react";
import axios from 'axios';
import { navigate } from '@reach/router';
import { store } from "react-notifications-component";
import "../scss/CompleteRegistration.scss";
import { Button, Input, Label } from "semantic-ui-react";
// import cloverLogo from "../Assets/clover_new.png";
// import shopifyLogo from "../Assets/shopify_new.png";

import ContentTopline from "../components/ContentTopline";

const CompleteRegistration = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')
  const [code, setCode] = useState('')
  const [trueCode, setTrueCode] = useState(false)
  const [verifyEmail, setVerifyEmail] = useState(false)
  const [merchantName, setMerchantName] = useState('')

  const handleGetCode = () => {
    const alfabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    let newCode = ''
    while (newCode.length < 6) {
      newCode += alfabet[Math.floor(Math.random() * alfabet.length)]
    }
    let data = new FormData();
    if (!email) {
      store.addNotification({
        message: "Please, enter email",
        type: "warning",
        container: "bottom-right",
        dismiss: {
          duration: 5000,
        }
      });
    } else {
    data.append('email', email);
    data.append('code', newCode)
    axios.put('https://omnigateway.net/app/signUp/verifyEmail',
      data)
      .then(function (res) {
        store.addNotification({
          message: "Code has been sent to your email",
          type: "info",
          container: "bottom-right",
          dismiss: {
            duration: 5000,
          }
        });
        setVerifyEmail(true)
      })
      .catch(function (error) {
        store.addNotification({
          message: error.response.data,
          type: "danger",
          container: "bottom-right",
          dismiss: {
            duration: 5000,
          }
        });
        setVerifyEmail(false)
      })
    }
  }

  const handleVerifyEmail = () => {
    if (code) {
      axios({
        method: 'GET',
        url: 'https://omnigateway.net/app/signUp/verifyEmail',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          "email": email,
          "code": code,
        }
      })
        .then(function (res) {
          store.addNotification({
            message: "Code verification successful",
            type: "success",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
          });
          setTrueCode(true)
        })
        .catch(function (error) {
          store.addNotification({
            message: "Wrong code",
            type: "danger",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
          });
          setTrueCode(false)
        })
    } else {
      store.addNotification({
        message: "Please, enter code",
        type: "warning",
        container: "bottom-right",
        dismiss: {
          duration: 5000,
        }
      });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      return store.addNotification({
        message: "Please, enter email",
        type: "warning",
        container: "bottom-right",
        dismiss: {
          duration: 5000,
        }
      });
    }
    if (!merchantName) {
      return store.addNotification({
        message: "Please, enter merchant name",
        type: "warning",
        container: "bottom-right",
        dismiss: {
          duration: 5000,
        }
      });
    }
    if (!code) {
      return store.addNotification({
        message: "Please, enter code",
        type: "warning",
        container: "bottom-right",
        dismiss: {
          duration: 5000,
        }
      });
    }
    if (!trueCode) {
      return store.addNotification({
        message: "Please, verify code before complete registration",
        type: "warning",
        container: "bottom-right",
        dismiss: {
          duration: 5000,
        }
      });
    }
    if (!password) {
      return store.addNotification({
        message: "Please, enter password",
        type: "warning",
        container: "bottom-right",
        dismiss: {
          duration: 5000,
        }
      });
    }
    if (password !== confirmPassword) {
      return store.addNotification({
        message: "Passwords must be the same",
        type: "warning",
        container: "bottom-right",
        dismiss: {
          duration: 5000,
        }
      });
    }
    if (password.length < 8) {
      return store.addNotification({
        message: "Minimum number of charecters for a password: 8",
        type: "warning",
        container: "bottom-right",
        dismiss: {
          duration: 5000,
        }
      });
    }
    if (trueCode && email && merchantName && password && password.length >= 8 && password === confirmPassword)
    return axios.post(`https://omnigateway.net/app/signUp`,
      {
        merchantName: merchantName,
        email: email,
        password: password,
        code: code,
      })
      .then(function (res) {
        navigate('/login')
      })
  };

  return (
    <div className="registration_page">
      <div className="registration_container">
        <ContentTopline title="Registration" />
        <form className="registration_form">
          <div className="registration_form-input_group">
            <Label className="registration_label">Email Address:</Label>
              <Input
                type="text"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <Button color="blue" size="small" type="button"  onClick={() => handleGetCode()}>Get Code</Button>
          </div>
          {verifyEmail ? (
            <div className="registration_hidden_form">
                 <div className="registration_form-input_group">
                <Label className="registration_label">Code from email:</Label>
                  <Input
                    type="text"
                    autoComplete="off"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <Button type="button" size="small" color="blue" onClick={() => handleVerifyEmail()}>Verify Code</Button>
              </div>
              <div className="registration_form-input_group">
                <Label className="registration_label">Merchant name:</Label>
                <Input
                  type="text"
                  autoComplete="off"
                  value={merchantName}
                  onChange={(e) => setMerchantName(e.target.value)}
                />
              </div>
              <div className="registration_form-input_group">
                <Label className="registration_label">Password:</Label>
                <Input
                  type="password"
                  autoComplete="off"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="registration_form-input_group">
                <Label className="registration_label">Confirm Password:</Label>
                <Input
                  type="password"
                  autoComplete="off"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <Button fluid size="small" color="blue" type="button" onClick={handleSubmit}>Sign up</Button>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default CompleteRegistration;
