import React, { useState } from "react";
import axios from 'axios';
import { store } from "react-notifications-component";
import { navigate } from '@reach/router';
import "../scss/Login.scss";

import cloverLogo from "../Assets/clover_new.png";
import shopifyLogo from "../Assets/shopify_new.png";

import ContentTopline from "../components/ContentTopline";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://omnigateway.net/auth/login',
      {},
      {
        headers: {
          login: email,
          password: password
        }
      })
      .then(function (res) {
        setLoginError(false)
        console.log(res);
        console.log(res.data.token);
        localStorage.setItem('AuthToken', res.data.token)
        localStorage.getItem('AuthToken')
        localStorage.setItem('tokenExpires', new Date(res.data.tokenExpires))
        localStorage.setItem('mid', res.data.mid)
        navigate('/')
      })
      .catch(function (error) {
        store.addNotification({
          message: "Wrong email or password",
          type: "danger",
          container: "bottom-right",
          dismiss: {
            duration: 5000,
          }
        });
        setLoginError(true)
      })
  };

  const handleSignUp = () => {
    navigate('/registration/')
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <ContentTopline title="Login" />
        <br></br>
        <h1 className="test">Welcome to OMNIGATEWAY</h1>
        <form className="form" onSubmit={handleSubmit}>
          <div className="field">
            <label className="label">Email Address</label>
            <input
              className="login_input"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="field">
            <label className="label">Password</label>
            <input
              className="login_input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="login_button_group">
            <button type="submit" className="button--large extra-margin-top">Login</button>
            <button type="button" onClick={() => handleSignUp()} className="button--large extra-margin-top">Sign up</button>
          </div>
        </form>
        {loginError ? (
          <div>
            Not a registered user? Explore by logging into your account on:{" "}
            <div className="logo-link-container">
              <a
                className="signin-link extra-margin"
                href="https://www.clover.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="logo--small" src={cloverLogo} alt="Clover" />
              </a>
              <a
                className="signin-link extra-margin"
                href="https://accounts.shopify.com/store-login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="logo--small " src={shopifyLogo} alt="Shopfiy" />
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Login;
