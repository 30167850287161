import React, { useState } from 'react';
import { store } from 'react-notifications-component';
import { Link } from '@reach/router';
import axios from 'axios';
import { Button, Icon, Input, Popup, Label } from 'semantic-ui-react';
import '../scss/Sync.scss';

import cloverLogo from '../Assets/clover_new.png';
import shopifyLogo from '../Assets/shopify_new.png';

import ContentTopline from '../components/ContentTopline';
import Spinner from '../components/Spinner/Spinner'

const Sync = (props) => {
  const {
    /* mid, */
    clMid,
    clName,
    shMid,
    shName,
    itemsCount,
    categoriesCount,

  } = props.userData;

  const [cloverEmail, setCloverEmail] = useState('')
  const [shopifyEmail, setShopifyEmail] = useState('')
  const [isShowCloverCode, setIsShowCloverCode] = useState(false)
  const [isShowShopifyCode, setIsShowShopifyCode] = useState(false)
  const [shopifyCode, setShopifyCode] = useState('')
  const [cloverCode, setCloverCode] = useState('')
  const [cloverError, setCloverError] = useState(false)
  const [shopifyError, setShopifyError] = useState(false)
  const [isOpenClover, setIsOpenClover] = useState(false)
  const [isOpenShopify, setIsOpenShopify] = useState(false)

  const handleChangeEmail = (e) => {
    switch (e.target.id) {
      case 'clover':
        setCloverEmail(e.target.value)
        break;
      case 'shopify':
        setShopifyEmail(e.target.value)
        break
      default:
        break;
    }
  }

  const handleChangeCode = (e) => {
    switch (e.target.id) {
      case 'clover':
        setCloverCode(e.target.value)
        break;
      case 'shopify':
        setShopifyCode(e.target.value)
        break
      default:
        break;
    }
  }

  const handleOpenPopup = (name) => {
    switch (name) {
      case 'clover':
        setIsOpenClover(true)
        setIsOpenShopify(false)
        break;
      case 'shopify':
        setIsOpenShopify(true)
        setIsOpenClover(false)
        break
      default:
        break;
    }
  }

  const handleRedirectToApp = (name) => {
    if (name === 'clover') {
      window.open(`https://www.clover.com/appmarket/m/${cloverEmail}/apps/VGD1C1C0T1D3P`, '_blank')
    } else {
      window.open('https://apps.shopify.com/clover-live-product-sync', '_blank')
    }
  }

  const handleSearchByEmail = (name) => {
    let data = new FormData();
    if (name === 'clover') {
      data.append('cloverMID', cloverEmail)
    } else {
      data.append('shDomain', shopifyEmail)
    }
    axios.post(`https://omnigateway.net/assignPlatform/${name === 'clover' ? 'Clover' : 'Shopify'}/search/${name === 'clover' ? 'cloverMID' : 'shStore'} `,
      data,
      {
        headers: {
          Authorization: `Bearer ${props.authToken}`,
          // Authorization: `Bearer D8VZ9OTKSSXX`,
        },
      }
    )
      .then((res) => {
        if (name === 'clover') {
          setIsShowCloverCode(true)
          setCloverCode('')
          setCloverError(false)
          store.addNotification({
            message: "Code has been sent to your email",
            type: "info",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
          });
        } else {
          setIsShowShopifyCode(true)
          setShopifyCode('')
          setShopifyError(false)
          store.addNotification({
            message: "Code has been sent to your email",
            type: "info",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
          });
        }
      })
      .catch((error) => {
        if (name === 'clover') {
          handleRedirectToApp('clover')
        } else {
          handleRedirectToApp('shopify')
        }
      })
  }

  const handleAssignPlatform = (name) => {
    axios.put(`https://omnigateway.net/api/${props.mid}/settings/assignPlatform`,
      {},
      {
        headers: {
          Authorization: `Bearer ${props.authToken}`,
          code: name === 'clover' ? cloverCode : shopifyCode,
          email: name === 'clover' ? cloverEmail : shopifyEmail,
          source: name === 'clover' ? 'Clover' : 'Shopify',
        },
      })
      .then((res) => {
        store.addNotification({
          message: "Platform was added",
          type: "success",
          container: "bottom-right",
          dismiss: {
            duration: 5000,
          }
        });
        props.fetchData(props.authToken)
      })
      .catch((error) => {
        if (error.request.status === 400) {
          store.addNotification({
            message: "Platform unidentified",
            type: "danger",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
          });
        }
        if (error.request.status === 403) {
          store.addNotification({
            message: `Open the app on ${name === 'clover' ? 'Clover' : 'Shopify'}`,
            type: "danger",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
          });
        }
      })
  }

  const handleClosePopup = () => {
    setCloverCode('')
    setShopifyCode('')
    setCloverEmail('')
    setShopifyEmail('')
    setIsShowCloverCode(false)
    setIsShowShopifyCode(false)
    setCloverError(false)
    setShopifyError(false)
    setIsOpenClover(false)
    setIsOpenShopify(false)
  }

  const handleRemovePlatform = (platform) => {
    axios.delete(`https://omnigateway.net/api/${props.mid}/settings/removePlatform/${platform === 'clover' ? 'Clover' : 'Shopify'}`,
      {
        headers: {
          Authorization: `Bearer ${props.authToken}`,
        },
      })
      .then((res) => {
        store.addNotification({
          message: "Platform was removed",
          type: "success",
          container: "bottom-right",
          dismiss: {
            duration: 5000,
          }
        });
        props.fetchData(props.authToken)
      })
  }

  const cloverAccountMarkup = clName ? (
    <div className='account-container'>
      <p>{clName}</p>
      <p className='caption'>MID: {clMid}</p>
      {clMid ?
        <Popup
          trigger={
            <p className='authenticated-caption'>
              Authentication Complete
            </p>
          }
          on='click'
          pinned
          content={
            <div className="sync_assign_platform_container">
              <Button size='small' color='blue' onClick={() => handleRemovePlatform('clover')}>
                Remove Platform
              </Button>
            </div>
          }
        />
        : (
          // <a className="authenticated-caption-error" href="https://www.clover.com/appmarket/apps/VGD1C1C0T1D3P/">
          //   Please add your Clover account
          // </a>
          <Popup
            trigger={<span className="authenticated-caption-error">Please add your Clover account</span>}
            on='click'
            onOpen={() => handleOpenPopup('clover')}
            pinned
            open={isOpenClover}
            content={isShowCloverCode ? (
              <div>
                <div className='sync_assign_platform_close_icon'>
                  <Icon name='close' color='blue' link onClick={() => handleClosePopup()} />
                </div>
                <div className="sync_assign_platform_container">
                  <Input placeholder="Code" id="clover" value={cloverCode} onChange={(e) => handleChangeCode(e)} />
                  <div>
                    <Button color="blue" size='small' onClick={() => handleAssignPlatform('clover')}>OK</Button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className='sync_assign_platform_close_icon'>
                  <Icon name='close' color='blue' link onClick={() => handleClosePopup()} />
                </div>
                <div className="sync_assign_platform_container">
                  <div>Provide your merchant ID where you'd like to install the Sync and we will take you straight to More Tools on Clover</div>
                  <Input placeholder="Your Clover Merchant ID" id="clover" value={cloverEmail} onChange={(e) => handleChangeEmail(e)} />
                  <div>
                    <Button color="blue" size='small' onClick={() => handleSearchByEmail('clover')}>Install on Clover</Button>
                  </div>
                </div>
              </div>)
            }
          />
        )}
      {props.firstSyncRender ? (
        <div className='clover-sync-container'>
          {props.itemsDone ? (
            <p className='sync-text spacer'>Items Synced</p>
          ) : (
            <p className='sync-text--orange spacer'>Syncing Items...</p>
          )}{' '}
          {props.categoriesDone ? (
            <p className='sync-text spacer'>Categories Synced</p>
          ) : (
            <p className='sync-text--orange spacer'>Syncing Categories...</p>
          )}
        </div>
      ) : null}
    </div>
  ) : (
    <div className='account-container'>
      <a
        className='signin-link'
        href='https://www.clover.com/appmarket/apps/VGD1C1C0T1D3P'
        target='_blank'
        rel='noopener noreferrer'
      >
        Sign in to Authenticate
      </a>
      <p className='create-helper'>
        Don't have a Clover Account?<span> </span>
        <a
          className='create-link'
          href='https://www.clover.com/goapply/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Start to create
        </a>
      </p>
    </div>
  );

  const shopifyAccountMarkup = shName ? (
    <div className='account-container'>
      <p>{shName}</p>
      <p className='caption'>MID: {shMid}</p>
      {shMid ?
        <Popup
          trigger={
            <p className='authenticated-caption'>
              Authentication Complete
            </p>
          }
          on='click'
          pinned
          content={
            <div className="sync_assign_platform_container">
              <Button size='small' color='blue' onClick={() => handleRemovePlatform('shopify')}>
                Remove Platform
              </Button>
            </div>
          }
        /> : (
          // <a className="authenticated-caption-error" href="https://apps.shopify.com/clover-live-product-sync">
          //   Please add your Shopify account
          // </a>}
          <Popup
            trigger={<span className="authenticated-caption-error">Please add your Shopify account</span>}
            on='click'
            pinned
            open={isOpenShopify}
            // onClose={() => handleClosePopup()}
            onOpen={() => handleOpenPopup('shopify')}
            content={isShowShopifyCode ? (
              <div>
                <div className='sync_assign_platform_close_icon'>
                  <Icon name='close' color='blue' link onClick={() => handleClosePopup()} />
                </div>
                <div className="sync_assign_platform_container">
                  <Input placeholder="Code" id="shopify" value={shopifyCode} onChange={(e) => handleChangeCode(e)} />
                  <Button fluid color="blue" size='small' onClick={() => handleAssignPlatform('shopify')}>OK</Button>
                </div>
              </div>
            ) : (
              <div>
                <div className='sync_assign_platform_close_icon'>
                  <Icon name='close' color='blue' link onClick={() => handleClosePopup()} />
                </div>
                <div className="sync_assign_platform_container">
                  <div>Provide your Shopify Domain where you'd like to install the Sync and we will take you straight to More Tools on Shopify</div>
                  <Input placeholder="Your Shopify Domain" id="shopify" value={shopifyEmail} onChange={(e) => handleChangeEmail(e)} />
                  <Button fluid color="blue" size='small' onClick={() => handleSearchByEmail('shopify')}>Install on Shopify</Button>
                </div>
              </div>)
            }
          />
        )}
      {props.firstSyncRender ? (
        <div>
          {props.shopifyDone ? (
            <p className='sync-text'>Shopify Synced</p>
          ) : (
            <p className='sync-text--orange'>Syncing to Shopify...</p>
          )}{' '}
        </div>
      ) : null}
    </div>
  ) : (
    <div className='account-container'>
      <a
        className='signin-link'
        href='https://apps.shopify.com/clover-live-product-sync'
        target='_blank'
        rel='noopener noreferrer'
      >
        Sign in to Authenticate
      </a>
      <p className='create-helper'>
        Don't have a Shopify Account?<span> </span>
        <a
          className='create-link'
          href='https://www.shopify.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Start to create
        </a>
      </p>
    </div>
  );

  return (
    <div>
      {!props.isLoading ? (
        <div>
          <ContentTopline title='Clover and Shopify Product Sync' />
          <div className='accounts'>
            <div className='clover-account'>
              <img className='logo' src={cloverLogo} alt='Clover' />
              {cloverAccountMarkup}
            </div>
            <div className='shopify-account'>
              <img className='logo' src={shopifyLogo} alt='Shopfiy' />
              {shopifyAccountMarkup}
            </div>
          </div>
          <div className='data-container'>
            <table className='table--sync'>
              <thead>
                <tr className='row--sync'>
                  <th className='th--sync'>Data to Sync</th>
                  <th className='th--sync'>Object Details</th>
                  <th className='th--sync'></th>
                  <th className='th--sync'></th>
                </tr>
              </thead>
              <tbody>
                <tr className='row--sync'>
                  <td className='td--sync'>Items/Products</td>
                  <td className='td--sync'>
                    {itemsCount ? `${itemsCount} Records` : '0 Records'}
                  </td>
                  <td className='td--sync'>
                    {itemsCount ? (
                      <div>
                        [Fields Mapped by Default]
                        <br />
                        <Link className='signin-link' to='/products'>
                          View Mapping
                        </Link>
                      </div>
                    ) : null}
                  </td>
                  <td className='td--sync sync-text'>Live Sync</td>
                </tr>
                <tr className='row--sync'>
                  <td className='td--sync'>Categories</td>
                  <td className='td--sync'>
                    {categoriesCount ? `${categoriesCount} Records` : '0 Records'}
                  </td>
                  <td className='td--sync'>
                    {categoriesCount ? (
                      <div>
                        [Categories Mapped by Default]
                        <br />
                        <Link className='signin-link' to='/categories'>
                          View Mapping
                        </Link>
                      </div>
                    ) : null}
                  </td>
                  <td className='td--sync sync-text'>
                    {props.buttonLoading ? (
                      <p>Loading...</p>
                    ) : (
                      <button
                        onClick={() => props.syncAction()}
                        className='button'
                      >
                        Batch Sync
                      </button>
                    )}
                  </td>
                </tr>
                <tr className='row--sync'>
                  <td className='td--sync'>Modifiers</td>
                  <td className='td--sync'></td>
                  <td className='td--sync'>
                    <br />
                    <p className='signin-link'></p>
                  </td>
                  <td className='td--sync sync-text'>Soon</td>
                </tr>
                <tr className='row--sync'>
                  <td className='td--sync'>Labels</td>
                  <td className='td--sync'></td>
                  <td className='td--sync'>
                    <br />
                    <p className='signin-link'></p>
                  </td>
                  <td className='td--sync sync-text'>Soon</td>
                </tr>
                <tr className='row--sync'>
                  <td className='td--sync'>Taxes</td>
                  <td className='td--sync'></td>
                  <td className='td--sync'>
                    <br />
                    <p className='signin-link'></p>
                  </td>
                  <td className='td--sync sync-text'>Soon</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : <Spinner />}
    </div>
  );
}

export default Sync;
