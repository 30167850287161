import React from "react";

import "../scss/UserSetup.scss";

import ContentTopline from "../components/ContentTopline";

const UserSetup = (props) => {
  //console.log(props);

  return (
    <div className="login-page">
      <div className="setup-container">
        <ContentTopline title="First Time Setup" />
        <h1 className="test">Welcome to OMNIGATEWAY</h1>
        <p>
          Please click the button below to run the first time sync. This action
          may require 15 minutes.
        </p>

        <button
          className="button--large extra-margin-top"
          onClick={() => props.firstSync()}
        >
          First Sync
        </button>
      </div>
    </div>
  );
};

export default UserSetup;
