import React from 'react';

import './spinner.css';

const Spinner = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <div className='loadingio-spinner-double-ring-3fy7aaww587'>
        <div className='ldio-1re3p65fv7t'>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
