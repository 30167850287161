import React, { useState, useEffect } from 'react';
import { Router, useLocation, navigate } from '@reach/router';
// import queryString from 'query-string';
import axios from 'axios';
import ReactNotification from 'react-notifications-component'
import moment from 'moment';
import './scss/App.scss';

import Header from './components/Header';
import Navbar from './components/Navbar';
import Sync from './pages/Sync';
import Products from './pages/Products';
import Categories from './pages/Categories';
import Login from './pages/Login';
import CompleteRegistration from './pages/CompleteRegistration';
import UserSetup from './pages/UserSetup';
import Rules from './pages/Rules';
import Catch from './functional-error-boundary';
import NetworkError from './pages/NetworkError';
import 'react-notifications-component/dist/theme.css'

/* localhost:3000/?mid=691&code=HD0A4QEI */

const errorHandler = (error, info) => {
  //console.log('error', error);
  //console.log('info', info);
};

const networkErrorHandler = (error) => {
  if (error.response) {
    // console.log('error.response.data', error.response.data);
    navigate('/error', {
      state: {
        message:
          'The request was made and the server responded with a status code that falls out of the range of 2xx',
        data: error.response.data,
      },
    });

    // console.log(error.response.headers);
  } else if (error.request) {
    navigate('/error', {
      state: {
        message: 'The request was made but no response was received',
        url: error.config.url,
        method: error.config.method,
      },
    });
  } else {
    // Something happened in setting up the request that triggered an Error
    //console.log('Error', error.message);

    navigate('/error', {
      state: {
        message: error.message,
      },
    });
  }
  // console.log(error.config);
};

const MyErrorBoundary = Catch(function MyErrorBoundary(props, error) {
  if (error) {
    //console.log('error', error);
    return (
      <div className='error-screen'>
        <h2>An error has occured</h2>
        <h4>{error.message}</h4>
      </div>
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
}, errorHandler);

const App = () => {
  const [state, setState] = useState({
    userData: {
      mid: null,
      clMid: null,
      clName: null,
      clDashboardUrl: null,
      shMid: null,
      shName: null,
      shDashboardUrl: null,
      itemsCount: null,
      categoriesCount: null,
    },
  });
  const [mid, setMid] = useState(null);
  // const [code, setCode] = useState(null);
  // const [platform, setPlatform] = useState(null)
  // const [storeId, setStoreId] = useState(null)
  const [authToken, setAuthToken] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [initialAuthReq, setInitialAuthReq] = useState(false);

  const [isInitSync, setInitSync] = useState(false);
  const [initSyncState, setInitSyncState] = useState([]);
  const [firstSyncRender, setFirstSyncRender] = useState(false);

  const [itemsDone, setItemsDone] = useState();
  const [categoriesDone, setCategoriesDone] = useState();
  const [shopifyDone, setShopfiyDone] = useState();
  const [isLoading, setIsLoading] = useState(false)

  const location = useLocation();

  // let url = location.search;

  // let midCode = queryString.parse(url);

  // 3 last network errors
  const pushNetworkError = (newError) => {
    let newState = initSyncState;
    if (newState.unshift(newError) > 3) newState.pop();
    setInitSyncState(newState);
    //console.log('initSyncState', initSyncState);
  };

  const initialSyncNetworkErrorHandler = (error) => {
    if (error.response) {
      pushNetworkError({
        message:
          'The request was made and the server responded with a status code that falls out of the range of 2xx',
        data: error.response.data,
      });

      // console.log(error.response.headers);
    } else if (error.request) {
      pushNetworkError({
        message: 'The request was made but no response was received',
        url: error.config.url,
        method: error.config.method,
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      //console.log('Error', error.message);

      pushNetworkError({
        message: error.message,
      });
    }
    // console.log(error.config);
  };

  // if (midCode.code && midCode.mid && midCode.platform && midCode.storeId) {
  //   if (!mid && !code && !platform && !storeId) {
  //     setMid(midCode.mid);
  //     setCode(midCode.code);
  //     setPlatform(midCode.platform)
  //     setStoreId(midCode.storeId)
  //   }
  // }

  const token = localStorage.AuthToken;
  const midStored = localStorage.mid;
  // const codeStored = localStorage.code;
  // const platformStored = localStorage.platform;
  // const storeIdStored = localStorage.storeId;

  if (!authToken) {
    if (token) {
      setAuthToken(token);
    }
    // if (location.href !== `${location.origin}/`) {
    //   if (location.href !== `${location.origin}/login`) {
    //     //uncomment this before push
    //     navigate(location.origin);
    //   }
    // }
  }

  if (!mid) {
    if (midStored) {
      setMid(midStored);
    }
  }

  const fetchData = (token) => {
    setIsLoading(true)
    const localMid = localStorage.getItem('mid')
    axios.get(`https://omnigateway.net/api/${localMid}/info`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const returnData = { userData: { ...res.data } };
        setState({ ...returnData });
        setInitialAuthReq(false);
        setTimeout(setIsLoading(false), 2000)
      })
      .catch((error) => {
        setTimeout(setIsLoading(false), 2000)
      })
  }


  /* if mid and if mid =/= mid stored > replacementMid = mid */

  /* I found it difficult to implement a solution so that when the user navigates to the HomePage (after the redirect provides the mid and code in url) the url params contain the users mid and code */

  useEffect(() => {
    setInitialAuthReq(true);
    // if (!res2.data.shMid || !res2.data.clMid) {
    //   axios.post(`https://omnigateway.net/api/${localMid}/settings/assignPlatform`,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         source: platformX,
    //         storeId: storeIdX,
    //       }
    //     })
    // }
    // setRegistrationCompleted(res2.data.registrationCompleted)
    // if (!res2.data.registrationCompleted) {
    //   setRegistrationCompleted(false)
    //   setEmail(res2.data.email)
    //   navigate('/registration')
    // } else {
    //   setRegistrationCompleted(true)
    // }

    // .catch(() => {
    //   console.log('fetch data catch');
    //   authAndData(midX, codeX, platformX, storeIdX)
    // });
    // console.log('first step');

    // const authAndData = async (midX, codeX, platformX, storeIdX) => {
    //   try {
    /* const dummyData = {
      userData: {
        mid: 691,
        clMid: "NQWCTQAWCFC24",
        clName: "Test Store7771495",
        clDashboardUrl: "https://www.clover.com/dashboard",
        shMid: 43007443093,
        shName: "Test Store7771495",
        shDashboardUrl: "https://test-store7771495.myshopify.com/admin",
        itemsCount: 1066,
        categoriesCount: 9,
      },
    }; */

    /* mid commented out in Sync.js, may be required for future functionality, uncomment when needed */

    // const auth = await axios
    //   .post(
    //     'https://omnigateway.net/auth',
    //     {},
    //     {
    //       headers: {
    //         mid: midX,
    //         code: codeX,
    //       },
    //     }
    //   )
    //   .catch(networkErrorHandler);
    // setInitialAuthReq(false);
    // let res;
    // if (auth) {
    //   setAuthToken(auth.data.token);
    //   localStorage.setItem('AuthToken', `${auth.data.token}`);
    //   localStorage.setItem('mid', `${midX}`);
    //   localStorage.setItem('code', `${codeX}`);

    //   res = await axios
    //     .get(`https://omnigateway.net/api/${mid}/info`, {
    //       headers: {
    //         Authorization: `Bearer ${auth.data.token}`,
    //       },
    //     })
    //     .then(res => {
    // if (!res.data.shMid || !res.data.clMid) {
    //   axios.post(`https://omnigateway.net/api/${midX}/settings/assignPlatform`,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${auth.data.token}`,
    //         source: platformX,
    //         storeId: storeIdX,
    //       }
    //     })
    // }
    //     })
    //     .catch(networkErrorHandler);
    // }

    // if (res) {
    //   const returnData = { userData: { ...res.data } };
    //   setState({ ...returnData });

    //   if (Object.keys(res.data).length === 0) {
    //     firstSync(auth.data.token);
    //   }
    // }
    /* setTimeout(() => {
      setState({ ...dummyData });
    }, 5000); */
    //   } catch (err) {
    //     console.error(err);
    //   }
    // };

    /* if altMid case */

    if (authToken) {
      fetchData(authToken);
    } else {
      // if (mid) {
      // if (code) {
      //   if (platform) {
      //     if (storeId) {
      // authAndData(mid, code, platform, storeId);
      //     }
      //   }
      // }
      // } else {
      if (location.href !== `${location.origin}/login`) {
        window.location.href = '/login';
      }
    }
    // }
    // setInitialAuthReq(false);
  }, [authToken]);

  useEffect(() => {
    const tokenExpires = localStorage.getItem('tokenExpires')
    if (moment(new Date()).isAfter(moment(tokenExpires))) {
      localStorage.clear()
      setAuthToken(undefined)
      setMid(null)
      navigate('/login')
    }
  }, [location.href])

  const syncCategoriesAction = async () => {
    /* const auth = await axios.post(
      "https://omnigateway.net/auth",
      {},
      {
        headers: {
          mid: mid,
          code: code,
        },
      }
    ); */

    /* used to use an extra request to gain the auth token, this implementation places the token in the App state. */
    if (state.userData.shMid && state.userData.clMid) {
      setButtonLoading(true);
      const SyncData = await axios
        .post(
          // `https://omnigateway.net/api/${mid}/sync/clover/categories`,
          `https://omnigateway.net/api/${mid}/sync/categories`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .catch(networkErrorHandler);
      setButtonLoading(false);
    } else {
      if (!state.userData.clMid) {
        return alert('Please add you Clover account')
      }
      if (!state.userData.shMid) {
        return alert('Please add your Shopify account')
      }
    }
  };

  const firstSync = async (AToken) => {
    navigate('/');
    setFirstSyncRender(true);

    const items = await axios
      .post(
        // `https://omnigateway.net/api/${mid}/import/clover/items`,
        `https://omnigateway.net/api/${mid}/import/items`,
        {},
        {
          headers: {
            Authorization: `Bearer ${AToken}`,
          },
        }
      )
      .catch(initialSyncNetworkErrorHandler);

    if (items) {
    }
    setItemsDone(true);

    const categories = await axios
      .post(
        // `https://omnigateway.net/api/${mid}/import/clover/categories`,
        `https://omnigateway.net/api/${mid}/import/categories`,
        {},
        {
          headers: {
            Authorization: `Bearer ${AToken}`,
          },
        }
      )
      .catch(initialSyncNetworkErrorHandler);

    if (categories) {
    }

    setCategoriesDone(true);

    const sync = await axios
      .post(
        // `https://omnigateway.net/api/${mid}/sync/clover`,
        `https://omnigateway.net/api/${mid}/sync`,
        {},
        {
          headers: {
            Authorization: `Bearer ${AToken}`,
          },
        }
      )
      .catch(initialSyncNetworkErrorHandler);
    if (sync) {
    }
    setShopfiyDone(true);
    // setInitSync(false);
  };

  const firstSync4Setup = async () => {
    setInitSync(true);

    const sync = await axios
      .post(
        `https://omnigateway.net/api/${mid}/sync`,
        // `https://omnigateway.net/api/691/sync`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            // Authorization: `Bearer D8VZ9OTKSSXX`,
          },
        }
      )
      .then((res) => {
        // window.location.reload();
        // console.log('res  === 200', res);
        if (res.status === 200) {
          // console.log('res.status === 200');
          // navigate('/login');

          window.location.reload();
          // navigate('/');
        }
      })
      .catch(initialSyncNetworkErrorHandler);

    //console.log('sync', sync);
    if (sync) {
    }
    // setShopfiyDone(true);
    setInitSync(false);
  };

  return (
    <MyErrorBoundary>
      <div className='App'>
        <ReactNotification />
        <Header mid={mid} authToken={authToken} />
        <div className='main-container'>
          {mid && authToken ? (
            <div className='navbar-container'>
              <Navbar userData={state.userData} mid={mid} />
            </div>
          ) : null}

          <div className={'content-container ' + (mid && authToken ? '' : 'fullwidth')}>
            <div className='app-content'>
              <Router>
                <Sync
                  path='/'
                  userData={state.userData}
                  syncAction={syncCategoriesAction}
                  initSyncAction={firstSync4Setup}
                  isInitSync={isInitSync}
                  initSyncState={initSyncState}
                  buttonLoading={buttonLoading}
                  firstSyncRender={firstSyncRender}
                  itemsDone={itemsDone}
                  categoriesDone={categoriesDone}
                  shopifyDone={shopifyDone}
                  initialAuthReq={initialAuthReq}
                  authToken={authToken}
                  mid={mid}
                  fetchData={fetchData}
                  isLoading={isLoading}
                />
                <Products path='/products'
                  mid={mid}
                />
                <Categories path='/categories'
                  mid={mid}
                />
                <Login path='/login' />
                <Rules path='/rules'
                  mid={mid}
                  token={authToken}
                  userData={state.userData}
                />
                <UserSetup path='/usersetup' firstSync={firstSync} />
                <NetworkError path='/error' />
                <CompleteRegistration
                  path='/registration'
                />
                <Login default />
              </Router>
            </div>
          </div>
        </div>
      </div>
    </MyErrorBoundary>
  );
};

export default App;
